import { encryptBalance, decryptBalance } from "./encryptionBalance";

export const addUserToLocalStorage = (user) => {
  const encryptedBalance = encryptBalance(user?.balance, process.env.REACT_APP_CRYPTO_SALT)
  const encryptedUser = {...user, balance: encryptedBalance }
  localStorage.setItem("user", JSON.stringify(encryptedUser));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem("user");
  if(result){
    const user = JSON.parse(result)
  const decryptedBalance = decryptBalance(user?.balance , process.env.REACT_APP_CRYPTO_SALT);
  return {...user , balance: decryptedBalance  }
  }
  return null
  // const user = result ? JSON.parse(result) : null;
  // return user;
};
export const newUser = (newUser)=>{
  localStorage.setItem("newUser", JSON.stringify(newUser));
}
export const getNewUser = ()=>{
  const newUser =  localStorage.getItem("newUser");
  return JSON.parse(newUser)
}


export const addCountHoverTranslateToLocalStorage = (count) => {
  localStorage.setItem("count", count)
}

export const removeCountHoverTranslateFromLocalStorage = () => {
  localStorage.removeItem("count")
}

export const getCountHoverTranslateFromLocalStorage = () => {
  return localStorage.getItem("count")
}


// start time
export const addStartTimeToLocalStorage = (time) => {
  localStorage.setItem("startTime", time)
}

export const getStartTimeFromLocalStorage = () => {
  localStorage.getItem("startTime")
}

export const removeStartTimeFromLocalStorage = () => {
  localStorage.removeItem("startTime")
}

// end time
export const addEndTimeToLocalStorage = (time) => {
  localStorage.setItem("endTime", time)
}

export const getEndTimeFromLocalStorage = () => {
  localStorage.getItem("endTime")
}

export const removeEndTimeFromLocalStorage = () => {
  localStorage.removeItem("endTime")
}