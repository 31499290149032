export const getFirstDayOfMonth = (date) => {
    // Get the year and month of the given date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Create a new Date object for the first day of the month
    const firstDayOfMonth = new Date(year, month, 1);

    return firstDayOfMonth;
}

export const getLastDayOfMonth = (date) => {
    // Get the year and month of the given date
    const year = date.getFullYear();
    const month = date.getMonth();

    // Create a new Date object for the first day of the next month
    const firstDayOfNextMonth = new Date(year, month + 1, 1);

    // Subtract one millisecond to get the last millisecond of the current month
    const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 1);

    return lastDayOfMonth;
}

export const areSameMonth = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
}

export const isTimeStartMonth = () => {
    // Get the current date
    const currentDate = new Date();

    // Check if it's the first day of the month
    const isFirstDayOfMonth = currentDate.getDate() === 1;

    // Check if it's the first second of the first day of the month
    const isFirstDayOfMonthSecond = isFirstDayOfMonth && currentDate.getHours() === 0 && currentDate.getMinutes() === 0 && currentDate.getSeconds() === 0;

    return isFirstDayOfMonth;
}

export const isTimeEndMonth = () => {
    // Get the current date
    const currentDate = new Date();

    // Get the last date of the month
    const lastDateOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    // Check if it's the last day of the month
    const isEndOfMonth = currentDate.getDate() === lastDateOfMonth.getDate();

    // Check if it's the last second of the last day of the month
    const isEndOfMonthSecond = isEndOfMonth && currentDate.getHours() === 23 && currentDate.getMinutes() === 59 && currentDate.getSeconds() === 59;

    return isEndOfMonthSecond;
}

export const getFirstTimeOfMonth = () => {
    // Create a new Date object for the current date
    const currentDate = new Date();

    // Set the date to the first day of the month (day 1)
    currentDate.setDate(1);

    // Set the time components to 0 (midnight)
    currentDate.setHours(0);
    currentDate.setMinutes(0);
    currentDate.setSeconds(0);
    currentDate.setMilliseconds(0);

    // Get the milliseconds of the first moment of the month
    const millisecondsOfFirstOfMonth = currentDate.getTime();

    return millisecondsOfFirstOfMonth;
}

export const getLastTimeOfMonth = () => {
    // Create a new Date object for the current date
    const currentDate = new Date();

    // Set the date to the last day of the month
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

    // Set the time components to the end of the day
    lastDayOfMonth.setHours(23);
    lastDayOfMonth.setMinutes(59);
    lastDayOfMonth.setSeconds(59);
    lastDayOfMonth.setMilliseconds(999);

    // Get the milliseconds of the last moment of the month
    const millisecondsOfLastOfMonth = lastDayOfMonth.getTime();

    return millisecondsOfLastOfMonth;
}

export const convertToVietnamTime = (dateString, timeZone) => {
    // Create a Date object from the input date string and timezone
    const date = new Date(dateString);
    const options = {
        timeZone: 'Asia/Ho_Chi_Minh',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    };

    // Use Intl.DateTimeFormat to format the date to Vietnam time
    const formatter = new Intl.DateTimeFormat('en-US', options);
    const parts = formatter.formatToParts(date);

    // Construct the formatted date string
    const vietnamTime = parts.reduce((acc, part) => {
        acc[part.type] = part.value;
        return acc;
    }, {});

    return `${vietnamTime.year}-${vietnamTime.month}-${vietnamTime.day} ${vietnamTime.hour}:${vietnamTime.minute}:${vietnamTime.second}`;
}

export const getMonthOfDatetime = datetime => {
    let date = new Date(datetime);

    let month = date.getMonth();

    return month;
}

export const getYearOfDatetime = datetime => {
    let date = new Date(datetime);

    let year = date.getFullYear();

    return year;
}