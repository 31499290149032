import React, { useEffect, useState } from "react";
import { CountryDropdown } from "react-country-region-selector";
import "../../assets/css/BillingInfo.css";
import api from "../../utils/axios";
import { toast } from "react-toastify";
import { API_URL } from "../../constants/constants";
import { useTranslation } from "react-i18next";

const Europe_Country_List = [
  "Austria",
  "Belgium",
  "Bulgaria",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
];

export default function BillingInfo({setIsShowDepositModal}) {
  const { t } = useTranslation();
  const [data, setData] = useState({
    name: "",
    company: "",
    country: "",
    is_eu: false,
    state: "",
    address: "",
    city: "",
    postal: "",
    isVATable: "",
    vat: "",
    vat_checked: false,
    // invoice: false,
  });

  useEffect(() => {
    const getData = async () => {
      await api
        .get(`${API_URL}/api/v1/billing`)
        .then((res) => {
          const {
            name,
            company,
            country,
            is_eu,
            state,
            address,
            city,
            postal,
            isVATable,
            vat,
            vat_checked,
            // invoice,
          } = res.data;
          setData({
            name,
            company,
            country,
            is_eu,
            state,
            address,
            city,
            postal,
            isVATable,
            vat,
            vat_checked,
            // invoice,
          });
        })
        .catch((err) => {
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    };

    getData();
  }, []);

  const onChange = (e) => {
    let tempData = { ...data };
    tempData[e.target.name] = e.target.value;
    setData(tempData);
  };

  const onChangeCountry = (name, value) => {
    let tempData = { ...data };
    tempData.is_eu = Europe_Country_List.includes(value);
    tempData[name] = value;
    setData(tempData);
  };

  // const onChangeInvoice = () => {
  //   let tempData = { ...data };
  //   tempData["invoice"] = !tempData["invoice"];
  //   setData(tempData);
  // };

  const onChangeVatCheck = () => {
    let tempData = { ...data };
    tempData["vat_checked"] = !tempData["vat_checked"];
    setData(tempData);
  };

  const onSave = async () => {
    // if (data?.name === "" || data?.country === "" || (data?.is_eu && data?.vat === "" && data?.isVATable === "yes") || (data?.is_eu && data?.isVATable === "")) {
    //   toast.error("Please fill in all required fields (*)");
    //   return;
    // }
    if (data?.name === "") {
      toast.error("Fullname is required");
      return;
    }
    if (data?.country === "") {
      toast.error("Country is required");
      return;
    }

    if (data?.is_eu && data?.isVATable === "") {
      toast.error("The option to provide a VAT number is required")
      return;
    }

    if (data?.is_eu && data?.isVATable === "yes" && data?.vat === "") {
      toast.error("VAT number is required")
      return;
    }

    // if (data.vat_checked && data.vat == "") {
    //   toast.error("You have to input VAT number.");
    //   return;
    // }

    const check_vat_number = await api.post(`${API_URL}/api/v1/billing/check-vat-number`, { vat: data["vat"] })
    // toast.success(check_vat_number ? "true" : "false");
    const {
      name,
      company,
      country,
      is_eu,
      state,
      address,
      city,
      postal,
      isVATable,
      vat,
      vat_checked,
      vat_valid = check_vat_number.data.valid
    } = data;
    await api
      .post(`${API_URL}/api/v1/billing`, {
        name,
        company,
        country,
        is_eu,
        state,
        address,
        city,
        postal,
        isVATable,
        vat,
        vat_checked,
        vat_valid
      })
      .then((res) => {
        toast.success("Completed");
        setIsShowDepositModal(false);
      })
      .catch((err) => {
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });

  };

  return (
    <div className="billing-info">
      <div className="title">{t("billing_info")}</div>
      <div className="billing-input-group">
        <div className="subtitle d-flex">
          {t("full_name")} &nbsp;
          <div style={{ color: "rgb(255,0,0)" }}>*</div>
        </div>
        <input
          value={data.name}
          name="name"
          placeholder="e.g. John Smith"
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle d-flex">
          {t("company_name")} &nbsp;
          {/* <div style={{ color: "rgb(255,0,0)" }}>*</div> */}
        </div>
        <input
          value={data.company}
          name="company"
          placeholder="e.g. Best Company Ever"
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle d-flex">
          {t("country")} &nbsp;
          <div style={{ color: "rgb(255,0,0)" }}>*</div>
        </div>
        <CountryDropdown
          value={data.country}
          onChange={(val) => onChangeCountry("country", val)}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle">{t("state/province/territory")}</div>
        <input
          value={data.state}
          name="state"
          placeholder=""
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle">{t("address")}</div>
        <input
          value={data.address}
          name="address"
          placeholder="Street"
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle">{t("city")}</div>
        <input
          value={data.city}
          name="city"
          placeholder="Vienna"
          onChange={onChange}
        />
      </div>
      <div className="billing-input-group">
        <div className="subtitle">{t("postal_code")}</div>
        <input
          value={data.postal}
          name="postal"
          placeholder=""
          onChange={onChange}
        />
      </div>
      {
        data.is_eu && <div style={{ marginBottom: "30px" }}>
          <div className="subtitle d-flex">{t("do_you_want_to_provide_vat_number")} &nbsp;
            <div style={{ color: "rgb(255,0,0)" }}>*</div>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", gap: 30 }}>
            <label style={{ display: "flex", gap: 7, alignItems: "center" }}>
              <input style={{ width: "22px", height: "22px", cursor: "pointer" }} type="radio" value="yes" checked={data?.isVATable === "yes"} name="isVATable" id="isVATable-yes" onChange={onChange} />
              <p>Yes </p>
            </label>
            <label style={{ display: "flex", gap: 7, alignItems: "center" }}>
              <input style={{ width: "22px", height: "22px", cursor: "pointer" }} type="radio" value="no" checked={data?.isVATable === "no"} name="isVATable" id="isVATable-no" onChange={onChange} />
              <p>No</p>
            </label>
          </div>
        </div>
      }
      {
        data.is_eu && <div className="billing-input-group">
          <div className="subtitle d-flex">{t("vat_number")} &nbsp;
            <div style={{ color: "rgb(255,0,0)" }}>*</div>
          </div>
          <input value={data.vat} placeholder="e.g. GE50507857895" name="vat" onChange={onChange} />
          <div className="d-flex align-items-center mt-2">
            <input
              checked={data.vat_checked}
              name="vatcheck"
              type="checkbox"
              id="vatcheck"
              style={{
                width: "24px",
                height: "24px",
                marginRight: "10px",
                border: "solid 1px #d9ebff !important",
                cursor: "pointer",
              }}
              onChange={onChangeVatCheck}
            />
            <label htmlFor="vatcheck d-flex" style={{ cursor: "pointer" }}>
              {t("I am eligible for reverse charge with my VAT Number.")} 
              {/* &nbsp;
              <div style={{ color: "rgb(255,0,0)" }}>*</div> */}
            </label>
          </div>
        </div>
      }
      {/* <div className="billing-input-group">
        <div className="subtitle">Invoices</div>
        <div className="d-flex align-items-center">
          <input
            checked={data.invoice}
            name="invoice"
            type="checkbox"
            id="invoice"
            style={{
              width: "24px",
              height: "24px",
              marginRight: "10px",
              border: "solid 1px #d9ebff !important",
              cursor: "pointer",
            }}
            onChange={onChangeInvoice}
          />
          <label htmlFor="invoice" style={{ cursor: "pointer" }}>
            Yes, email my billing info and original invoices.
          </label>
        </div>
      </div> */}
      <button className="save-btn" onClick={onSave}>
        {t("save_changes")}
      </button>
    </div>
  );
}
