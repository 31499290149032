import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../assets/css/VideoMeeting.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { SocketContext } from "../../context/socket";
import moment from 'moment-timezone';
const ct = require('countries-and-timezones');

function formatDate(date) {
    // Extract day, month, and year components from the date object
    const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if needed
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();

    // Concatenate components into the desired format
    return `${day}/${month}/${year}`;
}

const VideoMeeting = ({ show, setShow }) => {
    const ref = useRef(null);
    const { t } = useTranslation();
    const socket = useContext(SocketContext);
    const currentTask = useSelector((state) => state.current);
    const managerInfo = currentTask.users.filter((user) => user.role === 0)[0]
    const userData = getUserFromLocalStorage()

    const [selectedDate, setSelectedDate] = useState(formatDate(new Date()))
    const [selectedHour, setSelectedHour] = useState('')
    const [selectedCountry, setSelectedCountry] = useState('Europe/Berlin')

    // timezone selected
    const [nextTimezone, setNextTimezone] = useState(0)

    // +,- for GMT
    const [calculation, setCalculation] = useState("");

    // timezone will change
    const [timeList, setTimeList] = useState([
        '07:00 AM',
        '07:30 AM',
        '08:00 AM',
        '08:30 AM',
        '09:00 AM',
        '12:00 PM',
        '12:30 PM',
        '13:00 PM',
        '14:30 PM',
        '15:00 PM',
        '15:30 PM',
        '16:00 PM',
    ])

    //  timezones default
    const [_timeListTemp, setTimeListTemp] = useState([
        '09:00 AM',
        '09:30 AM',
        '10:00 AM',
        '10:30 AM',
        '11:00 AM',
        '14:00 PM',
        '14:30 PM',
        '15:00 PM',
        '15:30 PM',
        '16:00 PM',
        '16:30 PM',
        '17:00 PM',
    ])

    // const countries = Object.entries(ct.getAllCountries());

    const timeZones = moment.tz.names();

    const getGMTOffset = (timeZone) => {
        const offsetInMinutes = moment.tz(timeZone).utcOffset();
        const offsetHours = Math.abs(Math.floor(offsetInMinutes / 60));
        const offsetMinutes = Math.abs(offsetInMinutes % 60);
        const sign = offsetInMinutes >= 0 ? '+' : '-';
        return `GMT ${sign}${offsetHours}:${offsetMinutes.toString().padStart(2, '0')}`;
    };

    useEffect(() => {
        // get timezone selected
        let timeZoneSelected = []
        timeZoneSelected = timeZones.filter(timeZone => timeZone == selectedCountry)

        // get GMT hour selected 
        let gmtHourSelected = getGMTOffset(timeZoneSelected[0]).split(" ")[1].split("")[1]

        setCalculation(getGMTOffset(timeZoneSelected[0]).split(" ")[1].split("")[0])

        setNextTimezone(gmtHourSelected)
    }, [selectedCountry, calculation])


    useEffect(() => {
        let timeListTemp = []

        timeListTemp = _timeListTemp.map((time, idx) => {
            // '05:00 PM'
            let hour = +(time.split(" ")[0].split(":")[0])
            let minute = +(time.split(" ")[0].split(":")[1])

            let hourTime; 
            if (calculation === "+") {
                hourTime = hour + +nextTimezone - 2
            } 
            if (calculation === "-") {
                let distanceTimezone = Math.abs(-2 - +nextTimezone)
                hourTime = hour - distanceTimezone 
            }
          
            let ampm = hourTime >= 12 ? "PM" : "AM"

            let hourTimeTemp 
            if (hourTime >= 12) {
                hourTimeTemp = hourTime - 12
                ampm = "PM"
            } else hourTimeTemp = hourTime
            let timeTemp = `${hourTimeTemp}:${minute !== 0 ? minute : "00"} ${ampm}`

            timeListTemp.push(timeTemp)
            setTimeList(timeListTemp)
        })
    }, [nextTimezone])

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShow(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const handleSelectedDate = (datetime) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };

        const formattedDate = datetime.toLocaleDateString('en-GB', options); // Assuming 'en-GB' locale for dd/MM/yyyy format
        setSelectedDate(formattedDate);
    }

    const sendMeetingInfo = async () => {
        if (!selectedDate || !selectedCountry || !selectedHour) {
            toast.error("Please select all fields")
            return;
        }
        // const userIds = currentTask.users.filter(item => item._id != userData._id);
        const userIds = currentTask.users
        console.log(userIds);

        socket.emit("new-meeting",
            {
                userIds,
                userName: userData.username,
                userEmail: userData.email,
                userAvatar: userData.avatar,
                userRole: userData.role,
                createdAt: new Date().toUTCString(),
                date: selectedDate,
                hour: selectedHour,
                timezones: `(${getGMTOffset(selectedCountry)}) ${selectedCountry}`
            }
        )
        setTimeList([
            '09:00 AM',
            '09:30 AM',
            '10:00 AM',
            '10:30 AM',
            '11:00 AM',
            '02:00 PM',
            '02:30 PM',
            '03:00 PM',
            '03:30 PM',
            '04:00 PM',
            '04:30 PM',
            '05:00 PM',
        ])
        toast.success("Thank you for your video call booking! You will get soon a link to the booked meeting.")
        setShow(false)
    }

    return (
        <>
            {show && (
                <div className="video-meeting d-flex justify-content-center align-items-center">
                    <div ref={ref} className="video-meeting-model">
                        <div className="d-flex justify-content-between title">
                            <div style={{ fontSize: '25px' }}>{t("create_a_meeting_with_management")}</div>
                            <button className="cancel" onClick={() => setShow(false)}>
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
                        <div className="d-flex justify-content-between title gap-3 py-4">

                            <div className="profile-section">
                                <div style={{ textAlign: 'center' }}>
                                    <img src={currentTask.users[currentTask.users.length - 1].avatar} alt="Jenny Wilson" className="profile-img" />
                                    <h2 className="profile-username">{currentTask.users[currentTask.users.length - 1].username}</h2>
                                    <p className="description">
                                        {t("video_meeting_intro")}
                                        {/* Hi, I'm {currentTask.users[currentTask.users.length - 1].username}. I’m happy to assist you regarding your task in a personal meeting. */}
                                    </p>
                                </div>
                                <div className="info" >
                                    <div className="info-item" style={{ textAlign: 'left' }}>
                                        <i className="bi bi-clock icon"></i>
                                        <span style={{ fontSize: '15px', color: '#666' }}>30 {t("minutes")}</span>
                                    </div>
                                    <div className="info-item" style={{ textAlign: 'left' }}>
                                        <i className="bi bi-camera-video icon"></i>
                                        <span style={{ fontSize: '15px', color: '#666', lineHeight: '22px' }}>{t("video_meeting_detail")}</span>
                                    </div>
                                </div>
                                <hr />
                                <h4 style={{ fontSize: '16px', color: '#666' }}>{t("connect_with_me")}</h4>
                                <div className="social-links">
                                    <a target="_blank" href="https://www.linkedin.com/in/patrick-fuchshofer-0973271b6/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=at"><i className="bi bi-linkedin manager-info-icon-in"></i></a>
                                    {/* <i className="bi bi-twitter manager-info-icon-tw"></i> */}
                                </div>
                            </div>
                            <div className="schedule-section">
                                <h2>{t("select_datetime")}</h2>
                                <DatePicker
                                    dateFormat={'dd/MM/yyyy'}
                                    // autoFocus
                                    // open
                                    minDate={new Date()}
                                    onChange={handleSelectedDate}
                                    className="date-select-picker"
                                    customInput={
                                        <input
                                            className="example-custom-input"
                                        ></input>
                                    }
                                    selectedDates={selectedDate}
                                    value={selectedDate}
                                />
                                <div className="timezone">
                                    <label for="timezone">{t("timezone")}</label>
                                    <select id="timezone" title="lll" value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
                                        <option value="Europe/Berlin"><i className="bi bi-globe2"></i>{"(" + getGMTOffset("Europe/Berlin") + ")"} Europe/Berlin</option>
                                        {
                                            // countries.map(country => {
                                            //     return <option value={`${country[1].name} (${country[1].timezones[0]})`}>{country[1].name} ({country[1].timezones[0]})</option>
                                            // })
                                            timeZones.map((timeZone, idx) => {
                                                return <option key={idx} value={timeZone}>{"(" + getGMTOffset(timeZone) + ")"} {timeZone}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <div className="time-picker-section">
                                <div className="times">
                                    {
                                        timeList?.map((time, idx) => {
                                            return <button key={idx} onClick={() => setSelectedHour(time)} className={time == selectedHour ? "time active" : "time"}>{time}</button>
                                        })
                                    }
                                </div>

                                <button className="confirm-btn" onClick={sendMeetingInfo}>{t("confirm")}</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export const MemoizedVideoMeeting = React.memo(VideoMeeting);