import React, { useState, useEffect, useRef, useContext } from "react";
// import "../../assets/css/ContactManager.css";
import "../../assets/css/PayForReferrerModal.css";
import api from "../../utils/axios";
import { API_URL } from "../../constants/constants";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../context/socket";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { updateTask } from "../../redux/referrrerTaskSlice";

const PayForReferrerModal = ({ show, setShow, code, cost, isPaid, taskId, receiverId }) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const dispatch = useDispatch();
    const socket = useContext(SocketContext);

    const [referrerData, setReferrerData] = useState({});

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setShow(false);
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        const getReferrerData = async (code) => {
            await api.get(`${API_URL}/api/v1/user/${code}`)
                .then((res) => {
                    setReferrerData(res.data);
                })
        }

        getReferrerData(code);
    }, [code, show]);

    // useEffect(() => {
    //     const getReferrerData = async (code) => {
    //         await api.get(`${API_URL}/api/v1/user/${code}`)
    //             .then((res) => {
    //                 console.log(res.data);
    //                 setReferrerData(res.data);
    //             })
    //     }

    //     getReferrerData(code);
    // }, [code, show]);

    // useEffect(() => {
    //     console.log(receiverId);
    //     const getReceiverData = async (receiverId) => {
    //         await api.post(`${API_URL}/api/v1/user/receiver`, receiverId)
    //             .then((res) => {
    //                 console.log(res.data);
    //             })
    //     }
    //     getReceiverData(receiverId);
    // }, [receiverId])

    const onConfirm = async () => {
        if (isPaid) {
            toast.warning(`You are paid for ${referrerData?.username}`)
            return;
        }
        await api.post(`${API_URL}/api/v1/user/add-money`, { taskId, code, cost: Math.ceil(cost * 20 / 100) })
            .then((res) => {
                setShow(false);
                dispatch(updateTask(taskId));
                socket.emit("pay-referrer", { email: referrerData?.email, username: res.data.referrer.username, moneyReceived: Math.ceil(cost * 20 / 100), costOfTask: cost, avatarReferrer: res.data.referrer.avatar })
                toast.success(`Transfer money to ${referrerData?.username} successfully`);
            })
    }

    return (
        <>
            {show && (
                <div className="pay-for-referrer-modal d-flex justify-content-center align-items-center">
                    <div ref={ref} className="pay-for-referrer-modal-model">
                        <div className="d-flex gap-5 justify-content-between title">
                            <div>Pay commission to referrers</div>
                            <button onClick={() => setShow(false)} className="cancel" >
                                <i className="bi bi-x-lg"></i>
                            </button>
                        </div>
                        <div className="" style={{fontWeight: "500", textDecoration: "underline"}}>
                            Referrer information
                        </div>
                        <div className="d-flex mt-4" style={{marginLeft: "20px"}}>
                            <p>Gift code: </p>&nbsp;
                            <p className="text-data">{referrerData?.giftCode}</p>
                        </div>
                        <div className="d-flex mt-4" style={{marginLeft: "20px"}}>
                            <p>Email: </p>&nbsp;
                            <p className="text-data">{referrerData?.email}</p>
                        </div>
                        <div className="d-flex mt-4" style={{marginLeft: "20px"}}>
                            <p>Username: </p>&nbsp;
                            <p className="text-data">{referrerData?.username}</p>
                        </div>
                        <div className="d-flex mt-4" style={{marginLeft: "20px"}}>
                            <p>Balance: </p>&nbsp;
                            <p className="text-data">€{referrerData?.balance}</p>
                        </div>
                        <div className="mt-4" style={{fontWeight: "500", textDecoration: "underline"}}>
                            Task information
                        </div>
                        <div className="d-flex mt-4" style={{marginLeft: "20px"}}>
                            <p>Cost:</p>&nbsp;
                            <p className="text-data">€{cost}</p>
                        </div>
                        <div className="d-flex mt-4" style={{marginLeft: "20px"}}>
                            <p>Commission(20%):</p>&nbsp;
                            <p className="text-data">€{Math.ceil(cost * 20 / 100)}</p>
                        </div>
                        {
                            !isPaid && <div className="text-end mt-3">
                                <button className="send" onClick={onConfirm}>
                                    {t("confirm")}
                                </button>
                            </div>
                        }
                    </div>
                </div>
            )}
        </>
    )
}

export default PayForReferrerModal