import { createSlice } from "@reduxjs/toolkit";
import { ROLE } from "../constants/constants";
import * as moment from "moment";

export const taskSlice = createSlice({
  name: "task",
  initialState: [],
  reducers: {
    // This is for logout
    deleteAllTasks: (state) => {
      state.splice(0, state.length);
    },
    addTask: (state, action) => {
      const result = state.filter((item) => item._id == action.payload._id);
      if (result.length == 0) state.push(action.payload);
      state.slice().sort((a, b) =>  moment(b.updatedTime).diff(moment(a.updatedTime)));
    },
    updateTask: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == task._id) {
          state[i].priority = task.priority;
          state[i].status = task.status;
          state[i].workeffort = task.workeffort;
          state[i].duedate = task.duedate;
          state[i].status = task.status;
          state[i].hourlyrate = task.hourlyrate;
          state[i].fasthourlyrate = task.fasthourlyrate;
          state[i].fastduedate = task.fastduedate;
          state[i].duringdays = task.duringdays;
          state[i].fastduringdays = task.fastduringdays;
          state[i].updatedTime = new Date();
        }
    },
    setTasks: (state, action) => {
      state.splice(0, state.length);
      const tasks = [...action.payload];
      for (let i = 0; i < tasks.length; i++) state.push(tasks[i]);
    },
    addEstimate: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == task._id) {
          state[i].workeffort = task.workeffort;
          state[i].duedate = task.duedate;
          state[i].status = task.status;
          state[i].hourlyrate = task.hourlyrate;
          state[i].fasthourlyrate = task.fasthourlyrate;
          state[i].fastduedate = task.fastduedate;
          state[i].duringdays = task.duringdays;
          state[i].fastduringdays = task.fastduringdays;
          state[i].updatedTime = new Date();
          state[i].updatedAt = new Date();
        }
    },
    addChangeOffer: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == task._id) {
          state[i].workeffort = task.workeffort;
          state[i].duedate = task.duedate;
          state[i].status = task.status;
          state[i].hourlyrate = task.hourlyrate;
          state[i].fasthourlyrate = task.fasthourlyrate;
          state[i].fastduedate = task.fastduedate;
          state[i].duringdays = task.duringdays;
          state[i].fastduringdays = task.fastduringdays;
          state[i].updatedTime = new Date();
        }
    },
    addConfirm: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == task._id) {
          state[i].selectedoffer = task.selectedoffer;
          state[i].cost = task.cost;
          state[i].status = task.status;
          state[i].duedate = task.duedate;
          state[i].fastduedate = task.fastduedate;
          state[i].updatedTime = new Date();
        }
    },
    addAssign: (state, action) => {
      const { task, role } = action.payload;

      if (role != ROLE.DEV) {
        for (let i = 0; i < state.length; i++)
          if (state[i]._id == task._id) {
            state[i].status = task.status;
            state[i].developer = task.developer;
            state[i].users = task.users;
            state[i].unread = task.unread;
            state[i].updatedTime = new Date();
          }
      } else {
        state.push(task);
      }
    },
    addChangeProgress: (state, action) => {
      const task = action.payload;

      for (let i = 0; i < state.length; i++)
        if (state[i]._id == task._id) {
          state[i].percent = task.percent;
          state[i].updatedTime = new Date();
        }
    },
    addComplete: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == task._id) {
          state[i].status = task.status;
          state[i].updatedTime = new Date();
        }
    },
    addPay: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == task._id) {
          state[i].status = task.status;
          state[i].updatedTime = new Date();
        }
    },
    deleteTask: (state, action) => {
      const taskId = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == taskId) {
          state.splice(i, 1);
          break;
        }
    },

    addUnread: (state, action) => {
      const { task, user } = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == task._id) {
          for (let j = 0; j < state[i].unread.length; j++)
            if (state[i].unread[j].user == user._id)
              state[i].unread[j].number++;
        }
    },
    removeUnread: (state, action) => {
      const { taskId, userId } = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == taskId) {
          for (let j = 0; j < state[i].unread.length; j++)
            if (state[i].unread[j].user == userId)
              state[i].unread[j].number = 0;
        }
    },
    newMessage: (state, action) => {
      const { taskId, message } = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == taskId) {
          {
            state[i].updatedTime = new Date();
            state[i].latestMessage = {
              message: "",
              createdAt: new Date(),
            };
            state[i].latestMessage.message = message;
            state[i].latestMessage.createdAt = new Date();
          }
        }
      state.slice().sort((a, b) =>  moment(b.updatedTime).diff(moment(a.updatedTime)));
    },

    addOthersUnread: (state, action) => {
      const { taskId, senderId, userId } = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == taskId) {
          for (let j = 0; j < state[i].unread.length; j++)
            if (
              state[i].unread[j].user != userId &&
              state[i].unread[j].user != senderId
            )
              state[i].unread[j].number++;
        }
    },

    addOtherRead: (state, action) => {
      const { taskId, userId } = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == taskId) {
          for (let j = 0; j < state[i].unread.length; j++)
            if (state[i].unread[j].user == userId)
              state[i].unread[j].number = 0;
        }
    },
    submitRateTask: (state, action) => {
      const task = action.payload;
      for (let i = 0; i < state.length; i++)
        if (state[i]._id == task._id) {
          state[i].status = task.status;
          state[i].rating = task.rating;
          state[i].comment = task.comment;
          state[i].updatedTime = new Date();
        }
    },
  },
});

export const {
  deleteAllTasks,
  addTask,
  updateTask,
  setTasks,
  addEstimate,
  addChangeOffer,
  addConfirm,
  addAssign,
  addChangeProgress,
  addComplete,
  addPay,
  deleteTask,
  addUnread,
  removeUnread,
  newMessage,
  addOtherRead,
  addOthersUnread,
  submitRateTask,
} = taskSlice.actions;

export default taskSlice.reducer;
