import React, { useState, useContext, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { addTask, setTasks } from "../../redux/taskSlice";
import { SocketContext } from "../../context/socket";
import { setCurrentTask } from "../../redux/currentSlice";
import api from "../../../src/utils/axios";
import { API_URL, ROLE, DEADLINE, CLIENT_TYPE } from "../../constants/constants";
import { toast } from "react-toastify";
import {
  addUserToLocalStorage,
  getUserFromLocalStorage,
} from "../../utils/localStorage";
import { useTranslation } from "react-i18next";
import Form from 'react-bootstrap/Form';

export default function NewTask({ setShowNewTask }) {
  const { t } = useTranslation();
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = getUserFromLocalStorage();

  const [title, setTitle] = useState("");
  const [project, setProject] = useState("");
  const [priority, setPriority] = useState("Normal");
  const [deadline, setDeadline] = useState(DEADLINE.WITHIN_HOURS);
  const [isCreating, setIsCreating] = useState(false);
  const priorityList = ["Low", "Normal", "Urgent"];
  const deadlineList = [
    {
      value: DEADLINE.WITHIN_HOURS,
      text: "Within hours",
    },
    { value: DEADLINE.WITHIN_24HOURS, text: "Within 24 hours" },
    { value: DEADLINE.WITHIN_48HOURS, text: "Within 48 hours" },
  ];
  const titleRef = useRef(null);
  const priorityRef = useRef(null);
  const projectRef = useRef(null);
  const createbuttonRef = useRef(null);
  const [selectedClient, setSelectedClient] = useState();
  const [clientList, setClientList] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [role, setRole] = useState(0);
  const [addProject, setAddProject] = useState(true);

  // Set user role state
  useEffect(() => {
    const user = getUserFromLocalStorage();
    setRole(user.role);
    getAllProjects();
  }, []);

  const getAllProjects = () => {
    if (user.isprojectenable)
      api
        .get(`${API_URL}/api/v1/task/projects`)
        .then((res) => {
          const data = res.data;
          setProjectList([{
            _id: "",
            name: "",
          }, ...data]);
        })
        .catch((err) => console.log(err));
  };

  // Get client list for admin
  useEffect(() => {
    const getClient = async () => {
      const user = getUserFromLocalStorage();
      if (user.role == ROLE.ADMIN)
        api
          .get(`${API_URL}/api/v1/user/client`)
          .then((res) => {
            const data = res.data;
            if (data.length > 0) {
              setSelectedClient(data[0]._id);
            }
            setClientList([...data]);
          })
          .catch((err) => console.log(err));
    };
    getClient();
  }, []);

  const onChange = (e) => {
    setTitle(e.target.value);
  };

  const onProjectChange = (e) => {
    setProject(e.target.value);
  };

  const onCreate = async () => {
    setIsCreating(true);

    let user = getUserFromLocalStorage();
    const isnewuser = user.isnewuser;

    await api
      .post(`${API_URL}/api/v1/task/create`, {
        taskname: title,
        priority: priority,
        deadline: deadline,
        projectId: selectedProject,
        fasthourlyrate: 15,
        hourlyrate: 0
      })
      .then((res) => {
        setShowNewTask(false);
        if (user.isnewuser == true) {
          user.isnewuser = false;
          addUserToLocalStorage(user);
        }
        dispatch(addTask(res.data));
        // socket.emit("new-task", { task: res.data, isnewuser: isnewuser, userId: user._id });
        if (user.clientType === 0) socket.emit("new-task", { task: res.data, isnewuser: isnewuser, userId: user._id, clientType: CLIENT_TYPE.WP_GIFTCARD });
        else socket.emit("new-task", { task: res.data, isnewuser: isnewuser, userId: user._id, clientType: CLIENT_TYPE.AGENCY });
        setIsCreating(false);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
        setIsCreating(false);
      });

    // dispatch(
    //   addTask({
    //     taskname: title,
    //     cost: "",
    //     duedate: "",
    //     assigneddeveloper: "",
    //     priority: priority,
    //     estimated: false,
    //     workeffort: "",
    //     status: 0,
    //     createdtime: new Date().getTime(),
    //   })
    // );

    // dispatch(setCurrentTask(title));
  };

  const onCancel = () => {
    setShowNewTask(false);
  };

  const onCreateProject = async () => {
    await api
      .post(`${API_URL}/api/v1/task/createproject`, {
        name: project,
      })
      .then((res) => {
        getAllProjects();
        setAddProject(true);
        setProject("");
        setSelectedProject("");
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
        setIsCreating(false);
      });

  };

  const onCreatebyAdmin = async () => {
    setIsCreating(true);

    await api
      .post(`${API_URL}/api/v1/task/createbyadmin`, {
        taskname: title,
        priority: priority,
        client: selectedClient,
        projectId: selectedProject,
      })
      .then((res) => {
        setShowNewTask(false);
        dispatch(addTask(res.data));
        socket.emit("new-task", { task: res.data, isnewuser: false, userId: user._id });
        setIsCreating(false);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }
        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
        setIsCreating(false);
      });

    // dispatch(
    //   addTask({
    //     taskname: title,
    //     cost: "",
    //     duedate: "",
    //     assigneddeveloper: "",
    //     priority: priority,
    //     estimated: false,
    //     workeffort: "",
    //     status: 0,
    //     createdtime: new Date().getTime(),
    //   })
    // );

    // dispatch(setCurrentTask(title));
  };


  return (
    <>
      {role == ROLE.ADMIN ? (
        <div className="new-task">
          <input
            ref={titleRef}
            value={title}
            className="newtask-title"
            placeholder="Title"
            onChange={onChange}
            onKeyDown={(e) => {
              const { key } = e;
              if (key === "Enter") user && user.isprojectenable ? projectRef.current.focus() : priorityRef.current.focus();
            }}
          />
          {user && user.isprojectenable &&
            <>
              {
                addProject ?
                  <div className="d-flex mt-2 align-items-center">
                    <div
                      style={{
                        color: "#707C97",
                        fontSize: "18px",
                        marginRight: "10px",
                      }}
                    >
                      {t("project")}:
                    </div>
                    <select
                      className="estimate-select"
                      value={selectedProject}
                      onChange={(e) => setSelectedProject(e.target.value)}
                    >
                      {projectList.map((item, index) => {
                        return (
                          item &&
                          <option key={index} value={item._id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    <span className="mx-2" onClick={() => setAddProject(false)}>
                      <i className="bi bi-plus-lg"></i>
                    </span>
                  </div>
                  :
                  <>
                    {t("priority")}
                    <input
                      value={project}
                      ref={projectRef}
                      className="newtask-title w-75 mt-2"
                      placeholder="Project"
                      onChange={onProjectChange}
                      onKeyDown={(e) => {
                        const { key } = e;
                        if (key === "Enter") priorityRef.current.focus();
                      }}
                    />
                    <span className="mx-2" onClick={onCreateProject}>
                      <i className="bi bi-check-lg"></i>
                    </span>
                    <span className="mx-2" onClick={() => setAddProject(true)}>
                      <i className="bi bi-x-lg"></i>
                    </span>
                  </>
              }
            </>
          }
          <div className="d-flex justify-content-between">
            <div className="mt-3 text-title">
              {t("priority")}
              <select
                ref={priorityRef}
                className="priority-select"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                onKeyDown={(e) => {
                  const { key } = e;
                  if (key === "Enter") createbuttonRef.current.focus();
                }}
              >
                {priorityList.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mt-3 text-title">
              {t("client")}
              <select
                ref={priorityRef}
                className="priority-select"
                value={selectedClient}
                onChange={(e) => setSelectedClient(e.target.value)}
                onKeyDown={(e) => {
                  const { key } = e;
                  if (key === "Enter") createbuttonRef.current.focus();
                }}
              >
                {clientList.map((item, index) => {
                  return (
                    <option key={index} value={item._id}>
                      {item.username}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div>
            {priority === "Urgent" && (
              <div className="mt-3 text-title">
                {t("deadline")}
                <select
                  className="priority-select"
                  value={deadline}
                  onChange={(e) => setDeadline(e.target.value)}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (key === "Enter") createbuttonRef.current.focus();
                  }}
                >
                  {deadlineList.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>

          <div className="d-flex justify-content-end mt-3">
            <button className="cancel-button" onClick={onCancel}>
              {t("cancel")}
            </button>
            {isCreating ? (
              <button className="create-button">{t("creating...")}</button>
            ) : (
              <button
                ref={createbuttonRef}
                className="create-button"
                onClick={onCreatebyAdmin}
              >
                {t("create")}
              </button>
            )}
          </div>
        </div>
      ) : (
        <div className="new-task">
          {/* {t("Title")}
          <input
            ref={titleRef}
            value={title}
            className="newtask-title"
            placeholder="e.g. Create website"
            onChange={onChange}
            onKeyDown={(e) => {
              const { key } = e;
              if (key === "Enter") user && user.isprojectenable ? projectRef.current.focus() : priorityRef.current.focus();
            }}
          /> */}

          <Form>
            <Form.Group className="mb-3" >
              <Form.Label htmlFor="title">{t("Title")}</Form.Label>
              <Form.Control
                ref={titleRef}
                value={title}
                type="text"
                id="title"
                size="lg"
                placeholder="e.g. Create website"
                aria-describedby="passwordHelpBlock"
                style={{
                  border: '1px solid #CBD4D9'
                }}
                onChange={onChange}
                onKeyDown={(e) => {
                  const { key } = e;
                  if (key === "Enter") user && user.isprojectenable ? projectRef.current.focus() : priorityRef.current.focus();
                }}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
              <Form.Label htmlFor="select">{t("priority")}</Form.Label>
              <Form.Select id="select" style={{ width: '30%' }} ref={priorityRef} value={priority}
                onChange={(e) =>
                  setPriority(e.target.value)
                } aria-label="Default select example" onKeyDown={(e) => {
                  const { key } = e;
                  if (key === "Enter") createbuttonRef.current.focus();
                }}>
                {priorityList.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </Form.Select>

            </Form.Group>
            {priority === "Urgent" && (
              <Form.Group>
                <Form.Label htmlFor="urgent">{t("how_urgent")}</Form.Label>
                <Form.Select
                  id="urgent"
                  value={deadline}
                  onChange={(e) => setDeadline(e.target.value)}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (key === "Enter") createbuttonRef.current.focus();
                  }}
                >
                  {deadlineList.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>
            )}
          </Form>

          {user && user.isprojectenable &&
            <>
              {addProject ?
                <div className="d-flex mt-2 align-items-center">
                  <div
                    style={{
                      color: "#707C97",
                      fontSize: "18px",
                      marginRight: "10px",
                    }}
                  >
                    {t("project")}:
                  </div>
                  <select
                    className="estimate-select"
                    value={selectedProject}
                    onChange={(e) => setSelectedProject(e.target.value)}
                  >
                    {projectList.map((item, index) => {
                      return (
                        item &&
                        <option key={index} value={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <span className="mx-2" onClick={() => setAddProject(false)}>
                    <i className="bi bi-plus-lg"></i>
                  </span>
                </div>
                :
                <>
                  <input
                    value={project}
                    ref={projectRef}
                    className="newtask-title w-75 mt-2"
                    placeholder="Project"
                    onChange={onProjectChange}
                    onKeyDown={(e) => {
                      const { key } = e;
                      if (key === "Enter") priorityRef.current.focus();
                    }}
                  />
                  <span className="mx-2" onClick={onCreateProject}>
                    <i className="bi bi-check-lg"></i>
                  </span>
                  <span className="mx-2" onClick={() => setAddProject(true)}>
                    <i className="bi bi-x-lg"></i>
                  </span>
                </>
              }
            </>
          }
          <div className="d-flex justify-content-between">
            <div className="mt-3 text-title">
              {t("priority")}
              <select
                ref={priorityRef}
                className="priority-select"
                value={priority}
                onChange={(e) => setPriority(e.target.value)}
                onKeyDown={(e) => {
                  const { key } = e;
                  if (key === "Enter") createbuttonRef.current.focus();
                }}
              >
                {priorityList.map((item, index) => {
                  return (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  );
                })}
              </select>
            </div>
            {priority === "Urgent" && (
              <div className="mt-3 text-title">
                {t("how_urgent")}
                <select
                  className="priority-select"
                  value={deadline}
                  onChange={(e) => setDeadline(e.target.value)}
                  onKeyDown={(e) => {
                    const { key } = e;
                    if (key === "Enter") createbuttonRef.current.focus();
                  }}
                >
                  {deadlineList.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.text}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-end mt-3">
            <button className="cancel-button" onClick={onCancel}>
              {t("cancel")}
            </button>
            {isCreating ? (
              <button className="create-button">{t("creating...")}</button>
            ) : (
              <button
                ref={createbuttonRef}
                className="create-button"
                onClick={onCreate}
              >
                {t("create")}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
}
