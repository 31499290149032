import React, { useState, useEffect } from "react";
import { API_URL, CURRENCY, ROLE } from "../../constants/constants";
import api from "../../utils/axios";
import "../../assets/css/OnlineUser.css";

const TrackingClicked = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tracking, setTracking] = useState({})

    useEffect(() => {
        const getUsers = async () => {
            await api
                .get(`${API_URL}/api/v1/tracking/clicked`)
                .then((res) => {
                    setLoading(false)
                    setTracking(res.data);
                })
                .catch((err) => {
                    setLoading(false);
                })
                .finally(() => setLoading(false))
        };

        getUsers();
    }, []);

    // useEffect(() => {
    //     // console.log(users);
    //     let tempUser = [...users];
    //     tempUser = tempUser.filter((item) => item.username.includes(searchUsername))
    //     setUsers(tempUser)
    //     console.log(searchUsername);
    //     console.log(tempUser);

    //     //     tempTask = tempTask.filter((item) =>
    //     //   item.taskname.includes(searchTaskname)
    //     // );
    // }, [searchUsername])

    // const onToggleUserType = async (event, id) => {
    //     await api.post(`${API_URL}/api/v1/user/toggle-type`, {
    //         userId: id,
    //         isWpGiftcardClient: event.target.checked
    //     })
    //         .then((res) => {
    //             const data = res.data
    //             setUsers([...users.map(item => item._id === data._id ? { ...item, isWpGiftcardClient: data.isWpGiftcardClient } : item)])
    //         })
    //         .catch(err => {
    //             console.error(err);
    //         })
    // }

    return (
        <div className="online-user">
            <div>
                {loading ? (
                    <div className="mt-5 d-flex justify-content-center">
                        <div className="dot-windmill"></div>
                    </div>
                ) : (
                    <table>
                        <thead>
                            <tr>
                                {/* <th>Number Of Deposit Clicks</th> */}
                                {/* <th>Number Of Do It Later Clicks</th> */}
                                <th>Number Of Maintenance Clicks</th>
                                <th>Number Of Not Maintenance Clicks</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/* <td>{tracking.clickedDepositCount || 0}</td> */}
                                {/* <td>{tracking.clickedDoItLaterCount || 0}</td> */}
                                <td>{tracking.numOfMaintenanceClicks || 0}</td>
                                <td>{tracking.numOfNotMaintenanceClicks || 0}</td>
                            </tr>
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    );
}

export default TrackingClicked;