import React from "react";
import { useSelector } from "react-redux";
import MessageInputBox from "./MessageInputBox";
import MessageBox from "./MessageBox";

export default function ChatBoxContent() {
  const currentTask = useSelector((state) => state.current);

  return (
    <div className="chatbox-content d-flex flex-column justify-content-between">
      <div className="d-flex justify-content-center m-1 align-items-center">
        <div
          className="text-center"
          style={{
            wordBreak: "break-word",
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: "5px 20px",
            background: "#29AB87",
            color: "#fff",
            borderRadius: "10px",
            width: "100%",
          }}
        >
          {currentTask.taskname}
        </div>
      </div>
      <div className="flex-auto messagebox">
        <MessageBox />
      </div>
      <MessageInputBox />
    </div>
  );
}
