import React, { useEffect, useState, useRef, useCallback } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import ReactPlayer from 'react-player'
import NewTask from "./NewTask";
import {
  addAssign,
  addConfirm,
  addEstimate,
  addTask,
  addChangeProgress,
  addComplete,
  addPay,
  setTasks,
  addOtherRead,
  addChangeOffer,
  deleteTask,
  removeUnread,
  submitRateTask,
  updateTask,
  addOthersUnread,
  newMessage
} from "../../redux/taskSlice";
import { deleteCurrentTask, setCurrentTask } from "../../redux/currentSlice";
import { Route, useNavigate } from "react-router-dom";
import Task from "./Task";
import api from "../../utils/axios";
import { API_URL, CURRENCY, ROLE, STATUS, ADMIN_NAME, LANGUAGE } from "../../constants/constants";
import "../../assets/css/Tasks.css";
import { addUserToLocalStorage, getUserFromLocalStorage } from "../../utils/localStorage";
import { changeBalance } from "../../redux/loginSlice";
import { useTranslation } from "react-i18next";
import * as moment from "moment";
import { addSocketEventListener, removeAllListener } from "../../utils/socketCommon";
import Autocomplete from "react-autocomplete";

let taskLength = 0;
let selectedTask = {};

export default function Tasks({ socket }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tasks = useSelector((state) => state.task);
  const [role, setRole] = useState(ROLE.CLIENT);
  const [showNewTask, setShowNewTask] = useState(false);
  const navigate = useNavigate();
  // const socket = useContext(SocketContext);
  const task = useSelector((state) => state.current);
  const [selectedClient, setSelectedClient] = useState("000000000000");
  const [clientInput, setClientInput] = useState("");
  const [clientList, setClientList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [searchTaskname, setSearchTaskname] = useState("");

  const [status, setStatus] = useState("All");
  const [selectedProject, setSelectedProject] = useState("");
  const [priority, setPriority] = useState("All");
  const [showSearchPane, setShowSearchPane] = useState(true);

  const currentTaskRef = useRef(null);

  const [searchTasks, setSearchTasks] = useState([]);
  const [totalUserDue, setTotalDue] = useState(0);
  const [summary, setSummary] = useState({ total: 0, totalCost: 0 });
  const [user, setUser] = useState();
  const [userData, setUserData] = useState(() => getUserFromLocalStorage());
  const statusList = [
    t("to_estimate"),
    t("to_confirm"),
    t("to_do"),
    t("in_progress"),
    t("completed"),
    t("finished"),
    "",
    "",
    "",
    t("archived"),
    t("notLastMessage"),
    t("all"),
  ];

  const priorityList = [t("low"), t("normal"), t("urgent"), t("all")];

  // Set user role and Get client list for admin
  useEffect(() => {
    const getClient = async () => {
      const user = getUserFromLocalStorage();
      if (user) {
        setRole(user.role)
      }

      setTimeout(() => socket.emit("join-chat", user._id), 1000);
      addSocketEventListener(socket, "updated-user", (user) => {
        let currentUser = getUserFromLocalStorage();
        currentUser.balance = user.balance;
        currentUser.translatelanguage = user.translatelanguage;
        addUserToLocalStorage(currentUser);
      })

      if (user.role == ROLE.ADMIN)
        api
          .get(`${API_URL}/api/v1/user/client`)
          .then((res) => {
            let data = res.data;
            data.sort((a, b) => {
              return new Date(b.lastseen).valueOf() - new Date(a.lastseen).valueOf();
            })
            // console.log("user List", data);
            data.push({
              _id: "000000000000",
              username: "All",
            });
            setClientList([...data]);
          })
          .catch((err) => console.log(err));

      if (user.isprojectenable)
        api
          .get(`${API_URL}/api/v1/task/projects`)
          .then((res) => {
            const data = res.data;
            setProjectList([{
              _id: "",
              name: "",
            }, ...data]);
          })
          .catch((err) => console.log(err));
    };
    getClient();
    const tempUser = getUserFromLocalStorage();
    setUser(tempUser);
    const filterByClientId = new URLSearchParams(window.location.search).get('clientId');
    const filterByStatus = new URLSearchParams(window.location.search).get('status');
    filterByClientId && setSelectedClient(filterByClientId);
    filterByStatus && Number.isInteger(Number(filterByStatus)) && setStatus(statusList[Number(filterByStatus)]);
  }, []);

  useEffect(() => {
    if (user && user.isprojectenable)
      api
        .get(`${API_URL}/api/v1/task/projects`)
        .then((res) => {
          const data = res.data;
          setProjectList([{
            _id: "",
            name: "",
          }, ...data]);
        })
        .catch((err) => console.log(err));
  }, [showNewTask]);

  // This part is for moving to current task
  useEffect(() => {
    const goToTop = async () => {
      // console.log("task is changed");
      selectedTask = task;
      await delaytimeout(2000);
      scrollToTop();
    };

    // goToTop();
  }, [task]);

  // Task filtering and ordering
  useEffect(() => {
    taskLength = tasks.length;
    const user = getUserFromLocalStorage();
    let tempTask = [...tasks];
    let userTask = tasks.filter(item => item?.client?._id === user._id && item.status > STATUS.CONFIRMED && item.status < STATUS.PAID);
    let totalDueCost = 0;
    userTask.forEach(item => totalDueCost += (item.hourlyrate || 0) * (item.workeffort || 0));
    setTotalDue(totalDueCost);
    let totalTaskCost = 0;

    // Filter by taskname
    tempTask = tempTask.filter((item) =>
      item.taskname.includes(searchTaskname)
    );

    // Filter by client
    if (selectedClient !== "000000000000")
      tempTask = tempTask.filter((item) => item?.client?._id == selectedClient);

    // Filter by priority and
    if (priority !== "All" && status !== "All")
      tempTask = tempTask.filter(
        (item) => status == statusList[item.status] && priority == item.priority
      );
    else if (priority !== "All")
      tempTask = tempTask.filter((item) => priority == item.priority);
    else if (status !== "All" && status !== t('notLastMessage'))
      tempTask = tempTask.filter((item) => status == statusList[item.status]);

    // if(status === "All")
    //   tempTask = tempTask.filter((item) => item.status !== statusList.indexOf(t("archived")));

    if (selectedProject)
      tempTask = tempTask.filter(item => item.project && item.project._id === selectedProject);

    if (status === t('to_do') || status === t('in_progress')) {
      tempTask.forEach(item => { totalTaskCost += item.hourlyrate * item.workeffort })
    }

    if (status === t('notLastMessage')) {
      tempTask = tempTask.filter(item => item.latestMessage && item.latestMessage.sender && item.latestMessage.sender.role == ROLE.CLIENT);
    }
    // let unreadTempTask = [];
    // let readTempTask = [];
    // for (let i = 0; i < tempTask.length; i++) {
    //   let readFlag = true;
    //   for (let j = 0; j < tempTask[i].unread.length; j++) {
    //     if (
    //       tempTask[i].unread[j].user == user._id &&
    //       tempTask[i].unread[j].number != 0
    //     )
    //       readFlag = false;
    //   }
    //   if (readFlag) readTempTask.push(tempTask[i]);
    //   else unreadTempTask.push(tempTask[i]);
    // }

    // readTempTask.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
    // unreadTempTask.sort(
    //   (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt)
    // );

    // tempTask = readTempTask.concat(unreadTempTask);

    // Sort Task by latest change
    tempTask = tempTask.sort((a, b) => {
      return new Date(b.updatedTime).valueOf() - new Date(a.updatedTime).valueOf();
    });

    setSearchTasks(tempTask);
    setSummary({ total: tempTask.length, totalCost: totalTaskCost });

    if (
      task &&
      Object.keys(task).length === 0 &&
      Object.getPrototypeOf(task) === Object.prototype &&
      tempTask.length > 0
    ) {
      const CurrentTaskId = new URLSearchParams(window.location.search).get('taskId');

      const PreviousTaskId = localStorage.getItem("taskId");

      if (CurrentTaskId) {
        const selectedTask = tasks.filter(item => item._id == CurrentTaskId)[0];
        if (selectedTask) {
          dispatch(setCurrentTask(selectedTask));
          dispatch(removeUnread({ taskId: selectedTask._id, userId: user._id }));
          socket.emit("message-read", { task: selectedTask, userId: user._id });
        }
      } else if (PreviousTaskId) {
        const selectedTask = tasks.filter(item => item._id == PreviousTaskId)[0];
        if (selectedTask) {
          dispatch(setCurrentTask(selectedTask));
          dispatch(removeUnread({ taskId: selectedTask._id, userId: user._id }));
          socket.emit("message-read", { task: selectedTask, userId: user._id });
        }
      } else {
        dispatch(setCurrentTask(tempTask[tempTask.length - 1]));
        dispatch(
          removeUnread({ task: tempTask[tempTask.length - 1], user: user })
        );
        socket.emit("message-read", {
          task: tempTask[tempTask.length - 1],
          userId: user._id,
        });
      }
    }


  }, [status, priority, tasks, searchTaskname, selectedClient, selectedProject]);

  const sendMessage = async (message) => {
    const user = getUserFromLocalStorage();

    await api
      .post(`${API_URL}/api/v1/message/send/`, {
        message: message,
        images: [],
        files: [],
        taskId: task._id,
      })
      .then((res) => {
        // dispatch(addMessage(res.data));
        dispatch(newMessage({ taskId: task._id, message: res.data.message }));

        dispatch(
          addOthersUnread({
            taskId: task._id,
            userId: user._id,
            senderId: user._id,
          })
        );

        // After store message, emit message sent event
        socket.emit("new-message", res.data);
      })
      .catch((err) => {
        if (err.response.status == 401) {
          navigate("/");
        }

        const errors = err.response.data.errors;
        errors.forEach((error) => toast.error(error.msg));
      });
  };

  useEffect(() => {
    addSocketEventListener(socket, "change-status-for-task-done-no-response", async (task) => {
      let taskId = task?._id
      let client = task?.client

      // pay for task
      await api
        .post(`${API_URL}/api/v1/task/pay`, {
          taskId,
        })
        .then(async (res) => {
          const data = res.data;
          const user = getUserFromLocalStorage();
          const { cost } = data;
          dispatch(addPay(data));
          dispatch(changeBalance(-1 * cost));
          user.balance += -1 * cost;
          addUserToLocalStorage(user);
          socket.emit("new-pay", data);

          let message;

          if (client.translatelanguage) {
            await api
              .post(`${API_URL}/api/v1/message/translate/`, {
                message: `Hi ${ADMIN_NAME}, I just paid for the task.`,
                language: client.translatelanguage,
              })
              .then((res) => {
                // message = decodeEntities(
                //   res.data.data.translations[0].translatedText
                // );
              });
          } else {
            if (client.language == LANGUAGE.EN) {
              message = `Hi ${ADMIN_NAME}, I just paid for the task.`;
            } else if (client.language == LANGUAGE.DE)
              message = `Hallo ${ADMIN_NAME}, ich habe gerade für die Aufgabe bezahlt.`;
            else if (client.language == LANGUAGE.ES)
              message = `Hi ${ADMIN_NAME}, I just paid for the task.`;
          }

          sendMessage(message);
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });

      // task archived
      await api
        .post(`${API_URL}/api/v1/task/archived`, {
          taskId,
        })
        .then((res) => {
          dispatch(updateTask({ ...task, status: STATUS.ARCHIVED }))
          socket.emit("archive-task", { task });
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }
          const errors = err.response.data.errors;
          errors?.forEach((error) => toast.error(error.msg));
        });
    }
    )

    addSocketEventListener(socket, "task-received", (newTaskReceived) => {
      const user = getUserFromLocalStorage();

      toast.info(`Task «${newTaskReceived.taskname}» is created`, {
        theme: "colored",
      });

      dispatch(addTask(newTaskReceived));
      if (taskLength == 0 || newTaskReceived.client == user._id) {
        dispatch(setCurrentTask(newTaskReceived));
      }
      socket.emit("join-chat", newTaskReceived._id);
      addSocketEventListener(socket, "updated-user", (user) => {
        let currentUser = getUserFromLocalStorage();
        currentUser.balance = user.balance;
        currentUser.translatelanguage = user.translatelanguage;
        addUserToLocalStorage(currentUser);
      })
    });

    addSocketEventListener(socket, "task-deleted", (taskDeleteReceived) => {

      toast.info(`Task «${taskDeleteReceived.taskname}» is deleted`, {
        theme: "colored",
      });

      if (selectedTask._id == taskDeleteReceived._id)
        dispatch(deleteCurrentTask());
      dispatch(deleteTask(taskDeleteReceived._id));
    });

    addSocketEventListener(socket, "task-updated", (taskUpdateReceived) => {
      // console.log(`Task Updated: ${taskUpdateReceived}`);

      toast.info(`Task «${taskUpdateReceived.taskname}» is updated`, {
        theme: "colored",
      });
      dispatch(updateTask(taskUpdateReceived));
    });
    addSocketEventListener(socket, "task-archived", (taskArchiveReceived) => {
      // console.log(`Task Archived: ${taskArchiveReceived}`);

      toast.info(`Task «${taskArchiveReceived.taskname}» is archived`, {
        theme: "colored",
      });
      dispatch(updateTask(taskArchiveReceived));
    });

    addSocketEventListener(socket, "estimate-received", (newEstimateReceived) => {
      // console.log(`Estimate : ${newEstimateReceived}`);

      toast.info(`Task «${newEstimateReceived.taskname}» is estimated`, {
        theme: "colored",
      });

      dispatch(addEstimate(newEstimateReceived));
    });

    addSocketEventListener(socket, "changeoffer-received", (newChangeOfferReceived) => {
      // console.log(`Change Offer : ${newChangeOfferReceived}`);

      toast.info(`Task «${newChangeOfferReceived.taskname}» offer is changed`, {
        theme: "colored",
      });

      dispatch(addChangeOffer(newChangeOfferReceived));
    });

    addSocketEventListener(socket, "confirm-received", (newConfirmReceived) => {
      // console.log(`Confirm : ${newConfirmReceived}`);

      toast.info(`Task «${newConfirmReceived.taskname}» is confirmed`, {
        theme: "colored",
      });

      dispatch(addConfirm(newConfirmReceived));
    });

    addSocketEventListener(socket, "changeprogress-received", (newChangeProgressReceived) => {
      // console.log(`Progress Changed : ${newChangeProgressReceived}`);

      toast.info(
        `Task «${newChangeProgressReceived.taskname}» is done by ${newChangeProgressReceived.percent}%`,
        {
          theme: "colored",
        }
      );

      dispatch(addChangeProgress(newChangeProgressReceived));
    });

    addSocketEventListener(socket, "assign-received", (newAssignReceived) => {
      const user = getUserFromLocalStorage();

      // console.log(`Assigned : ${newAssignReceived}`);

      toast.info(`Task «${newAssignReceived.taskname}» is assigned`, {
        theme: "colored",
      });

      dispatch(addAssign({ task: newAssignReceived, role: user.role }));
    });

    addSocketEventListener(socket, "complete-received", (newCompleteReceived) => {
      // console.log(`Completed : ${newCompleteReceived}`);

      toast.info(`Task «${newCompleteReceived.taskname}» is completed`, {
        theme: "colored",
      });

      dispatch(addComplete(newCompleteReceived));
    });

    addSocketEventListener(socket, "pay-received", (newPayReceived) => {
      // console.log(`Paid : ${newPayReceived}`);

      const { cost } = newPayReceived;
      dispatch(changeBalance(cost));

      toast.info(`Task «${newPayReceived.taskname}» is paid`, {
        theme: "colored",
      });

      dispatch(addPay(newPayReceived));
    });

    addSocketEventListener(socket, "pay-fast-support-received", (newPayReceived) => {
      const { cost, task } = newPayReceived;
      dispatch(changeBalance(cost));

      toast.info(`Task «${task.taskname}» is paid for fast support`, {
        theme: "colored",
      });

      dispatch(addPay(newPayReceived));
    });

    addSocketEventListener(socket, "submitrate-received", (newSubmitRateReceived) => {
      // console.log(`Submit Rate : ${newSubmitRateReceived}`);

      const { cost } = newSubmitRateReceived;
      toast.info(`Task «${newSubmitRateReceived.taskname}» is get rate`, {
        theme: "colored",
      });

      dispatch(submitRateTask(newSubmitRateReceived));
    });

    addSocketEventListener(socket, "read-received", (data) => {
      const { taskId, userId } = data;
      const user = getUserFromLocalStorage();
      if (userId == user._id)
        dispatch(removeUnread({ taskId: taskId, userId }));
      else dispatch(addOtherRead({ taskId: taskId, userId: userId }));
    });

    return () => {
      removeAllListener(socket);
    };
  }, []);

  // Getting all task
  useEffect(() => {
    async function fetchData() {
      await api
        .get(`${API_URL}/api/v1/task`)
        .then((res) => {
          dispatch(setTasks(res.data));

          // handle delete task when client no response after 7 days
          let tasksTemp = [] // get tasks list done but not response after 7 days
          tasksTemp = res.data.filter(task => {
            let taskTimeLatestMessage = (new Date(task?.latestMessage?.createdAt)).getTime()
            const millisecondsInADay = 24 * 60 * 60 * 1000; // milliseconds

            // This task will be moved to paid & done in 7 days. Please reply to this chat if you have any objections.
           
            return taskTimeLatestMessage <= (new Date().getTime() - millisecondsInADay * 7) && task?.latestMessage?.sender?.role !== ROLE.CLIENT && task.status === STATUS.COMPLETED && task?.latestMessage?.message !== "This task will be moved to paid & done in 7 days, please reply to this chat if you have any objections"
          })
          // send messagebot to task no response after 7 days
          socket.emit("handle-task-done-no-response-after-7-days", tasksTemp)

          // delete task when client again no response after 14 days
          let tasksTemps2 // get tasks list done but not response after 14 days
          tasksTemps2 = res.data.filter(task => {
            let taskTimeLatestMessage = (new Date(task?.latestMessage?.createdAt)).getTime()
            const millisecondsInADay = 24 * 60 * 60 * 1000; // milliseconds
            return taskTimeLatestMessage <= (new Date().getTime() - millisecondsInADay * 7) && task?.latestMessage?.sender?.role !== ROLE.CLIENT && task.status === STATUS.COMPLETED && task?.latestMessage?.message === "This task will be moved to paid & done in 7 days, please reply to this chat if you have any objections"
          })
          socket.emit("handle-task-done-no-response-after-14-days", tasksTemps2)
        })
        .catch((err) => {
          if (err.response.status == 401) {
            navigate("/");
          }

          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    }
    fetchData();
  }, []);

  useEffect(() => {
    tasks.forEach((item) => {
      if (item._id == task._id) dispatch(setCurrentTask(item));
    });
  }, [tasks]);

  const scrollToTop = () => {
    currentTaskRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const delaytimeout = (delay) => {
    return new Promise((res) => setTimeout(res, delay));
  };

  return (
    <div
      id="task" // id for intro
      // className={`${showNewTask ? "scroll-hidden" : ""} tasks`}
      style={{ overflowY: "scroll", height: '100vh' }}
    >
      {/* <div ref={taskStartRef}></div> */}
      <div className="mt-5 tasks-button">
        <div className="tasks-title">{t("tasks")}</div>
        {role !== ROLE.DEV && (
          <button
            className="new-task-button"
            // className="new-task create-button"
            // className="cancel-button"
            onClick={() => setShowNewTask(true)}
          >
            + {t("new_task")}
          </button>
        )}
      </div>
      <div className="mt-5">
        {(showNewTask) ? <NewTask setShowNewTask={setShowNewTask} /> : <></>}
        {/* {(showNewTask && userData.role === ROLE.ADMIN)  ? <NewTask setShowNewTask={setShowNewTask} /> : <></>} */}
        {/* <NewTask /> */}
      </div>
      {/* <div className="mt-5">
        {userData.isnewuser && <div style={{height: '280px'}}>
          <ReactPlayer height={"100%"} width={"100%"} url='https://youtu.be/daslKHaX2LY' />
        </div>}
      </div> */}
      {!(showNewTask || tasks.length < 3) && (
        <div
          className="text-center"
          onClick={() => setShowSearchPane(!showSearchPane)}
          style={{ cursor: "pointer" }}
        >
          {showSearchPane ? (
            <i className="bi bi-chevron-double-up"></i>
          ) : (
            <i className="bi bi-chevron-double-down"></i>
          )}
        </div>
      )}
      {showNewTask || tasks.length < 3 ? (
        <></>
      ) : (
        showSearchPane && (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <div className="inprogress d-flex  align-items-center">
                <div
                  style={{
                    color: "#707C97",
                    fontSize: "24px",
                    marginRight: "10px",
                  }}
                >
                  <i className="bi bi-funnel-fill"></i>
                </div>
                <div>
                  <select
                    className="estimate-select"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    {statusList.map((item, index) => {
                      return (
                        (user && ((user.role === ROLE.CLIENT && item != t("notLastMessage")) || (user.role === ROLE.ADMIN || user.role === ROLE.DEV)) && item) &&
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {user && user.isprojectenable &&
                  <div className="d-flex  align-items-center">
                    <div
                      style={{
                        color: "#707C97",
                        fontSize: "18px",
                        marginRight: "10px",
                      }}
                    >
                      {t("project")}:
                    </div>
                    <div>
                      <select
                        className="estimate-select"
                        value={selectedProject}
                        onChange={(e) => setSelectedProject(e.target.value)}
                      >
                        {projectList.map((item, index) => {
                          return (
                            item &&
                            <option key={index} value={item._id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>}
              </div>
              <div className="d-flex  align-items-center">
                <div
                  style={{
                    color: "#707C97",
                    fontSize: "18px",
                    marginRight: "10px",
                  }}
                >
                  {t("priority")}:
                </div>
                <div>
                  <select
                    className="estimate-select"
                    value={priority}
                    onChange={(e) => setPriority(e.target.value)}
                  >
                    {priorityList.map((item, index) => {
                      return (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
            {role == ROLE.ADMIN && (
              <>
                <div className="inprogress d-flex  align-items-center mt-3">
                  <div
                    style={{
                      color: "#707C97",
                      fontSize: "24px",
                      marginRight: "10px",
                    }}
                  >
                    <i className="bi bi-search"></i>
                  </div>
                  <div className="flex-auto">
                    <input
                      className="task-search w-100"
                      placeholder="Search taskname"
                      value={searchTaskname}
                      onChange={(e) => setSearchTaskname(e.target.value)}
                    />
                  </div>
                </div>
                <div className="d-flex  align-items-center mt-3">
                  <div
                    style={{
                      color: "#707C97",
                      fontSize: "18px",
                      marginRight: "10px",
                    }}
                  >
                    {t("client")}:
                  </div>
                  <div>
                    <Autocomplete
                      getItemValue={(item) => item.id}
                      items={clientList.map(item => ({ name: item.username, id: item._id }))}
                      value={clientInput}
                      shouldItemRender={(item, value) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1}
                      onChange={(e) => setClientInput(e.target.value)}
                      inputProps={{
                        className: 'priority-select',
                        style: {
                          marginLeft: '0px'
                        }
                      }}
                      renderItem={(item, isHighlighted) =>
                        <div style={{ background: isHighlighted ? '#CBE7E9' : '#00676c', color: isHighlighted ? 'black' : 'white', paddingLeft: '10px' }}>
                          {item.name}
                        </div>
                      }
                      onSelect={(value, item) => { setSelectedClient(value); setClientInput(item.name); navigate('/chat') }}
                    />
                  </div>
                </div>
              </>
            )}
          </>
        )
      )}
      {(status === t('to_do') || status === t('in_progress')) &&
        <div className="mt-2 task-summary">{summary.total} {t('task_summary')} {summary.totalCost}{user && user.currency == CURRENCY.USD ? "$" : "€"} </div>
      }
      <div className={(status === t('to_do') || status === t('in_progress')) ? "mt-2" : "mt-5"}>
        {showNewTask ? (
          <></>
        ) : (
          searchTasks &&
          searchTasks
            .map((item, index) => (
              <div key={index}>
                {item._id == task._id ? (
                  <div ref={currentTaskRef}></div>
                ) : (
                  <></>
                )}
                <Task key={"task" + index} task={item} index={index} totalUserDue={totalUserDue} />
              </div>
            ))
        )}
      </div>
    </div>
  );
}
