import React, { useEffect, useState, useRef } from 'react'
import { Modal, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import "../assets/css/GiftModal.css"
import { toast } from "react-toastify";
import api from '../utils/axios'
import { API_URL } from '../constants/constants'
import { encodeMD5 } from '../utils/md5';
import { getUserFromLocalStorage } from '../utils/localStorage';
import { useSelector } from 'react-redux';

const GiftModal = ({ show, onHide }) => {
    const { t } = useTranslation();
    const ref = useRef(null);
    const [giftCode, setGiftCode] = useState("")

    const userData = getUserFromLocalStorage();

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                onHide();
            }
        };
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    }, []);

    const copyToClipboard = async (e) => {
        await api.post(`${API_URL}/api/v1/user/copied-gift-code`, { giftCode })
            .then((res) => {
                console.log(res.data);
            })

        navigator.clipboard.writeText("https://wp-sofa.chat/register-wp-giftcard/" + giftCode)
        e.target.focus();
        toast.success("Copied!")
    };

    useEffect(() => {
        setGiftCode(encodeMD5(userData._id));
    }, [show])

    const onCopyLink = () => {
        console.log("copy");
        navigator.clipboard.writeText(giftCode);
    }

    return (
        // <>
        //     {isShowGiftModal && (
        //         <div className="gift-modal d-flex justify-content-center align-items-center">
        //             <div ref={ref} className="gift-modal-model">
        //                 <div className="d-flex justify-content-between gap-5 title">
        //                     <div>Pay commission to referrers</div>
        //                     <button onClick={onHide} className="cancel" >
        //                         <i className="bi bi-x-lg"></i>
        //                     </button>
        //                 </div>
        //                 <section className='gift-modal' style={{}}>
        //                     <h2 className='' style={{ fontWeight: '400', fontSize: '20px', }}>{t("get_€60_for_each_new_customer_you_refer")}</h2>
        //                     <h5 style={{ margin: "20px 0" }}>{t("share_your_referral_link_with_friends_and_acquaintances_and_receive_your_commission_afterwards")}</h5>
        //                 </section>
        //                 <section className='gift-modal_link'>
        //                     <i style={{ marginRight: "17px" }} className="gift-icon bi bi-gift-fill"></i>
        //                     <div className='gift-modal_link_right'>
        //                         <p style={{ marginBottom: "10px" }}>{t("invitation_link")}</p>
        //                         <p style={{ fontStyle: "italic" }}>wp-sofa.chat/register-wp-giftcard/{giftCode}</p>
        //                     </div>
        //                 </section>
        //                 <section style={{ padding: '20px 0' }}>
        //                     <Button onClick={copyToClipboard} style={{ width: '100%', marginBottom: '15px', background: "linear-gradient('92.68deg', '#70D9DE', '0%', '#066E73', '100%')" }}>{t("copy_referral_link")}</Button>
        //                 </section>
        //                 <section>

        //                 </section>
        //             </div>
        //         </div>
        //     )}
        // </>
        <>
            {
                show && <Modal
                    size="md"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show}
                    onHide={onHide}>
                    <Modal.Header closeButton>
                        <h3 style={{ fontSize: "25px" }}>{t("invite")}</h3>
                    </Modal.Header>
                    <Modal.Body>
                        <section className='gift-modal' style={{}}>
                            <h2 className='' style={{ fontWeight: '400', fontSize: '20px', }}>{t("you_will_receive_20%_of_the_amount_from_the_first_task_of_the_person_you_referred")}</h2>
                            <h5 style={{ margin: "20px 0" }}>{t("share_your_referral_link_with_friends_and_acquaintances_and_receive_your_commission_afterwards")}</h5>
                        </section>
                        <section className='gift-modal_link'>
                            <i style={{ marginRight: "17px" }} className="gift-icon bi bi-gift-fill"></i>
                            <div className='gift-modal_link_right'>
                                <p style={{ marginBottom: "10px" }}>{t("invitation_link")}</p>
                                <p style={{ fontStyle: "italic", textDecoration: "underline" }}>wp-sofa.chat/register-wp-giftcard/{giftCode}</p>
                            </div>
                        </section>
                        <section style={{ padding: '20px 0 0' }}>
                            <Button onClick={copyToClipboard} style={{ width: '100%', marginBottom: '15px', background: "linear-gradient('92.68deg', '#70D9DE', '0%', '#066E73', '100%')" }}>{t("copy_referral_link")}</Button>
                        </section>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}

export default GiftModal