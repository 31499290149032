import React, { useEffect, useContext } from "react";
import ChatBoxTitle from "./ChatBoxTitle";
import ChatBoxContent from "./ChatBoxContent";
import { SocketContext } from "../../context/socket";
import "../../assets/css/ChatBox.css";
const ChatBox = () => {
  const socket = useContext(SocketContext);

  return (
    <div className="chatbox">
      <div className="chatbox-inner">
        <ChatBoxTitle />
        <ChatBoxContent />
      </div>
    </div>
  );
};

export default ChatBox;
