import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../utils/axios";
import { API_URL } from "../constants/constants";

export const fetchSwitchStatus = createAsyncThunk(
  'switch/fetchStatus',
  async () => {
    const res = await api.get(`${API_URL}/api/v1/user/isAdminOnline`)
    console.log(res.data.isAdminOnline);
    return res.data.isAdminOnline;
  }
)

export const updateSwitchStatus = createAsyncThunk(
  'switch/updateStatus',
  async (isOnline) => {
    const res = await api.post(`${API_URL}/api/v1/user/changeIsAdminOnline`, { isAdminOnline: isOnline })
    console.log(res.data.isAdminOnline);
    return res.data.isAdminOnline;
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    _id: "",
    email: "",
    username: "",
    role: 1,
    avatar: "",
    balance: 0,
    isAdminOnline: null,
    billingTab: 0,
    sidebarTab: "",
    isShowIntro: false,
    isShowGiftModal: false,
    isShowPayForReferrerModal: false,
  },
  reducers: {
    logout: (state) => {
      state._id = "";
      state.email = "";
      state.username = "";
      state.role = 1;
      state.avatar = "";
      state.balance = 0;
      state.isAdminOnline = false;
    },
    login: (state, action) => {
      const { _id, email, username, role, avatar, balance, isShowIntro } = action.payload;
      state._id = _id;
      state.email = email;
      state.username = username;
      state.role = role;
      state.avatar = avatar;
      state.balance = balance;
      state.isAdminOnline = true;
      state.isShowIntro = isShowIntro
    },
    changeBalance: (state, action) => {
      const balance = action.payload;
      state.balance += balance;
    },
    setBalance: (state, action) => {
      const balance = action.payload;
      state.balance = balance;
    },
    changeisAdminOnline: (state, action) => {
      state.isAdminOnline = !state.isAdminOnline
    },
    setBillingTab: (state, action) => {
      state.billingTab = action.payload;
    },
    setSidebarTab: (state, action) => {
      state.sidebarTab = action.payload;
    },
    showGiftModal: (state) => {
      state.isShowGiftModal = true;
    },
    hideGiftModal: (state) => {
      state.isShowGiftModal = false;
    },
    showPayForReferrerModal: (state) => {
      state.isShowPayForReferrerModal = true;
    },
    hidePayForReferrerModal: (state) => {
      state.isShowPayForReferrerModal = false;
    },
  },
  extraReducers: {
    [fetchSwitchStatus.fulfilled]: (state, action) => {
      state.isAdminOnline = action.payload;
    },
    [updateSwitchStatus.fulfilled]: (state, action) => {
      state.isAdminOnline = action.payload;
    }
  }
});

export const { login, logout, changeBalance, setBalance, changeisAdminOnline, setBillingTab, setSidebarTab, showGiftModal, hideGiftModal, showPayForReferrerModal, hidePayForReferrerModal } = loginSlice.actions;

export default loginSlice.reducer;
