import React, { useState, useEffect, useRef } from "react";
import api from "../../utils/axios";
import { API_URL, CURRENCY } from "../../constants/constants";
import "../../assets/css/ClientHistory.css";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/images/team.png";
import { getMonthOfDatetime, getYearOfDatetime } from "../../utils/dateTime";

export default function ClientHistory() {
  const { t } = useTranslation();
  const pdfExportComponent = useRef(null);
  const contentArea = useRef(null);
  const [clientsPayment, setClientsPayment] = useState([]);
  const [selected, setSelected] = useState(0);
  const [currency, setCurrency] = useState({ value: '$' });

  const [taxIsEnabled, setTaxIsEnabled] = useState(true);
  const [billingInfo, setBillingInfo] = useState({});
  const [country, setCountry] = useState("");

  useEffect(() => {
    const getClientsPayment = async () => {
      await api
        .get(`${API_URL}/api/v1/payment/clients`)
        .then((res) => {
          const data = res.data;
          const compareDates = (a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);

            if (dateA > dateB) {
              return -1;
            } else if (dateA < dateB) {
              return 1;
            } else {
              return 0;
            }
          };

          // Sort the array using the comparison function
          data.sort(compareDates);

          // Output the sorted array
          setClientsPayment([...data]);
          if (data.length > 0) {
            getClientBillingInfo(data[0].user._id);
          }
        })
        .catch((err) => {
          const errors = err.response.data.errors;
          errors.forEach((error) => toast.error(error.msg));
        });
    };

    getClientsPayment();
  }, []);

  const onPreview = async (index) => {
    setTaxIsEnabled(clientsPayment[index].taxIsEnabled);
    setSelected(index);
    let data = clientsPayment[index].user.currency === CURRENCY.USD ? "$" : "€";
    setBillingInfo({});
    setCurrency({ value: data });
    getClientBillingInfo(clientsPayment[index].user._id);
  };

  const onDownload = () => {
    savePDF(contentArea.current, {
      paperSize: "A4",
      fileName: clientsPayment[selected].invoiceId,
    });
  };

  const getClientBillingInfo = async (clientid) => {
    await api
      .post(`${API_URL}/api/v1/billing/client`, { clientid: clientid })
      .then((res) => {
        const billingInfo = res.data;
        setBillingInfo(billingInfo);
        let taxIsEnable = false;
        if (
          billingInfo.country == "" ||
          billingInfo.country == "Austria" ||
          (billingInfo.is_eu == true && billingInfo.vat_valid == false)
        ) {
          taxIsEnable = true;
        }
        setTaxIsEnabled(taxIsEnable);
        // setSelected(0);
        let CurrentCurrency = billingInfo.user.currency === CURRENCY.USD ? "$" : "€";
        setCurrency({ value: CurrentCurrency });
        setCountry(billingInfo.country);
      })
      .catch((err) => {
        setTaxIsEnabled(true);
        const errors = err.response.data.errors;
        errors?.forEach((error) => toast.error(error.msg));
      });
  };

  const calculateTotalAmountInMonth = (year, month) => {
    let totalAmount = 0
    for (let i = 0; i <= clientsPayment.length; i++) {
      if (getYearOfDatetime(clientsPayment[i]?.createdAt) === year && getMonthOfDatetime(clientsPayment[i]?.createdAt) === month) {
        totalAmount += clientsPayment[i].amount
      }
    }
    return totalAmount
  }

  return (
    <div className="clients-history">
      <div className="title">{t("customer_billing_history")}</div>
      <div className="mt-5 d-flex">
        <div>
          <table className="clients-history-table">
            <thead>
              <tr>
                <th>{/* <input type="checkbox" className="check" /> */}#</th>
                <th>{t("customer_name")}</th>
                <th>{t("date")}</th>
                <th>{t("invoice_id")}</th>
                <th>{t("total")}</th>
              </tr>
            </thead>
            <tbody>
              {clientsPayment?.map((item, index) => (
                <>
                  {/* <tr> */}
                    {
                      getMonthOfDatetime(clientsPayment[index]?.createdAt) !== getMonthOfDatetime(clientsPayment[index - 1]?.createdAt) && (
                        <tr>
                          <td colSpan={5} style={{borderTop: "1px solid #DADADA", paddingTop: "15px"}}>
                            <span style={{textDecoration: "underline", fontStyle: "italic"}}>Total revenue {getMonthOfDatetime(clientsPayment[index]?.createdAt) + 1}/{getYearOfDatetime(clientsPayment[index]?.createdAt)}:</span> <span style={{fontStyle: "italic", fontWeight: 500}}>€ {calculateTotalAmountInMonth(getYearOfDatetime(clientsPayment[index]?.createdAt), getMonthOfDatetime(clientsPayment[index]?.createdAt))}</span>
                          </td>
                        </tr>
                      )
                    }
                  {/* </tr> */}
                  <tr
                    className={index == selected ? "active" : ""}
                    key={index}
                    onClick={() => onPreview(index)}
                  >
                    <td>{index + 1}</td>
                    <td>{item?.user?.username}</td>
                    <td>{new Date(item?.createdAt).toLocaleDateString()}</td>
                    <td>{item?.invoiceId}</td>
                    <td>
                      {item?.user?.currency == CURRENCY.USD ? "$" : "€"}
                      {item?.amount}
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>

        {clientsPayment.length > 0 && (
          <div className="flex-auto">
            <button className="download-btn" onClick={onDownload}>
              <i className="bi bi-download"></i>&nbsp; {t("download")} (
              {clientsPayment[selected].invoiceId})
            </button>
            <div className="invoice-paper">
              <PDFExport ref={pdfExportComponent} paperSize="A4" margin="1.5cm">
                <div ref={contentArea} className="w-100 invoice">
                  <div className="header d-flex justify-content-between">
                    <div className="title">Invoice</div>
                    <img src={Logo} width="150px" />
                  </div>
                  <hr />
                  <div className="content" style={{ fontFamily: 'DejaVu Sans', width: '100%' }}>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="text-bold">Invoice ID</div>
                        <div>: {clientsPayment[selected].invoiceId}</div>
                      </div>
                      <div className="d-flex">
                        <div>Invoice has been paid at</div>
                        <div className="text-bold">
                          &nbsp;
                          {new Date(
                            clientsPayment[selected].createdAt
                          ).toLocaleDateString()}
                        </div>
                      </div>
                    </div>
                    <hr />
                    <div className="row">
                      <div className="col-6">
                        Buyer:
                        <div className="d-flex">
                          <div className="text-bold">Name</div>
                          <div>: {billingInfo.name}</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Email</div>
                          <div>
                            : {billingInfo.user && billingInfo.user.email}
                          </div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Address</div>
                          <div>: {billingInfo.address}</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Country</div>
                          <div>: {billingInfo.country}</div>
                        </div>
                        {billingInfo.postal && (
                          <div className="d-flex">
                            <div className="text-bold">Postal code</div>
                            <div>: {billingInfo.postal}</div>
                          </div>
                        )}
                        {billingInfo.company && (
                          <div className="d-flex">
                            <div className="text-bold">Company</div>
                            <div>: {billingInfo.company}</div>
                          </div>
                        )}
                        {billingInfo.vat && (
                          <div className="d-flex">
                            <div className="text-bold">VAT number</div>
                            <div>: {billingInfo.vat}</div>
                          </div>
                        )}
                      </div>
                      <div className="col-6">
                        Seller:
                        <div className="d-flex">
                          <div className="text-bold">Company</div>
                          <div>: Codemenschen GmbH</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Address</div>
                          <div>: Anton-Hubmann-Platz 1/6, 8077 Gössendorf,</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Country</div>
                          <div>: Österreich</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">UID-Nr</div>
                          <div>: ATU76037423</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Nr</div>
                          <div>: +436504861845</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Email</div>
                          <div>: office@codemenschen.at</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">Firmenbuchnummer</div>
                          <div>: FN 543274 h</div>
                        </div>
                        <div className="d-flex">
                          <div className="text-bold">FB-Gericht</div>
                          <div>: Landesgericht für Zivilrechtssachen Graz</div>
                        </div>
                      </div>
                    </div>
                    <div className="billing-table">
                      <table>
                        <thead>
                          <tr>
                            <th width="10%">#</th>
                            <th width="60%">DESCRIPTION</th>
                            {/* <th width="10%">RATE</th> */}
                            <th width="10%">QTY</th>
                            <th width="20%">AMOUNT</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              Wordpress Custom Development from wp-sofa.chat
                            </td>
                            <td>
                              {currency.value}
                              {taxIsEnabled
                                ? (
                                  (clientsPayment[selected].amount * 5) /
                                  6.0
                                ).toFixed(2)
                                : clientsPayment[selected].amount}
                            </td>
                            {/* <td>{1}</td> */}
                            <td>
                              {currency.value}
                              {taxIsEnabled
                                ? (
                                  (clientsPayment[selected].amount * 5) /
                                  6.0
                                ).toFixed(2)
                                : clientsPayment[selected].amount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-end total-billing-table">
                      <table>
                        <tbody>
                          <tr>
                            <td className="text-bold">
                              VAT {taxIsEnabled ? 20 : 0}%
                            </td>
                            <td>
                              {currency.value}
                              {taxIsEnabled
                                ? (
                                  clientsPayment[selected].amount / 6.0
                                ).toFixed(2)
                                : 0}
                            </td>
                          </tr>
                          <tr>
                            <td className="text-bold">Total</td>
                            <td>
                              {currency.value}
                              {clientsPayment[selected].amount}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <hr />
                    {taxIsEnabled ? (
                      <div>This invoice contains 20% value added tax</div>
                    ) : (<div>
                      In Austria, not taxable and tax As it is custom
                      software, is another service (provision of Information)
                      before. Since {country} is a third country, the rule
                      applies on the receiver location. The Performance is
                      therefore not taxable sales in Austria
                    </div>
                    )}
                    <br />
                    {!taxIsEnabled && billingInfo.is_eu &&
                      <>
                        <div>The invoice is issued without sales tax, since the tax liability of the recipient of the service
                          of the recipient of the service (reverse charge procedure).
                          The sales tax must be declared and paid by the recipient of the
                          paid by the service recipient.</div>
                        <div>
                          Der Rechnungsausweis erfolgt ohne Umsatzsteuer, da die Steuerschuldschaft des
                          Leistungsempfängers greif (Reverse-Charge-Verfahren)
                          Die Umsatzsteuer ist vom Leistungsempfänger anzumelden und
                          abzuführen.</div>
                      </>
                    }
                  </div>
                  <div className="footer"></div>
                </div>
              </PDFExport>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
